import React, { useEffect, useState } from 'react';
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import { List } from "flowbite-react";

function PolitiqueConfidentialite() {

    useEffect(() => {
        
    }, []);


    return (
        <div>
            <Menu displayButtons={true} />
            <div className="w-full px-4 py-12 pb-4 flex justify-center">
                <div className="max-w-screen-xl">
                    <h3 className="text-xl font-semibold">PLANILEO - POLITIQUE DE CONFIDENTIALITE</h3>
                    <p className="font-medium text-gray-900">Dernière mise à jour : 27 janvier 2025</p>
                    <p className="text-gray-500 mt-4">
                        Cette politique de confidentialité explique comment nous collectons, utilisons, protégeons et partageons vos données personnelles lorsque vous utilisez notre site web planileo.fr.
                        Nous nous engageons à respecter la législation applicable en matière de protection des données, y compris le Règlement général sur la protection des données (RGPD) de l'Union européenne.
                    </p>
                    <div className="mt-4">
                        <h3 className="mb-2 text-xl font-bold dark:text-white">1. Collecte des données personnelles</h3>
                        <p className="text-gray-500">
                            Lorsque vous utilisez le Site, nous collectons certaines informations personnelles que vous nous fournissez directement, ainsi que des informations collectées de manière automatisée. 
                            Ces données sont nécessaires à la gestion de nos services et à la relation commerciale avec vous.
                        </p>
                        <p className="text-gray-500 mt-2">Données collectées :</p>
                        <List className="mt-2">
                            <List.Item>Informations personnelles fournies par vous : nom, prénom, date de naissance, adresse postale (uniquement dans le cadre de la relation commerciale).</List.Item>
                            <List.Item>Informations collectées automatiquement : cookies fonctionnels nécessaires au bon fonctionnement du site et cookies analytiques pour améliorer l'expérience utilisateur.</List.Item>
                        </List>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">2. Utilisation des données personnelles</h3>
                        <p className="text-gray-500 mt-2">
                            Les données personnelles que nous collectons sont utilisées pour les finalités suivantes :
                        </p>
                        <List className="mt-2">
                            <List.Item>Gestion des abonnements et des paiements : Nous utilisons Stripe pour traiter les paiements récurrents liés à vos abonnements. Les informations nécessaires à ces transactions, telles que vos coordonnées de paiement, sont collectées par Stripe et sont régies par leur propre politique de confidentialité.</List.Item>
                            <List.Item>Gestion de la relation commerciale : Vos informations personnelles sont utilisées pour communiquer avec vous, gérer votre compte, et vous fournir les services auxquels vous avez souscrit.</List.Item>
                            <List.Item>Amélioration de l'expérience utilisateur : Les cookies analytiques nous permettent de mieux comprendre comment vous utilisez notre Site afin d'améliorer son fonctionnement et son contenu.</List.Item>
                        </List>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">3. Bases légales du traitement des données</h3>
                        <p className="text-gray-500">
                            Nous traitons vos données personnelles sur la base des fondements suivants :
                        </p>
                        <List className="mt-2">
                            <List.Item>Exécution d'un contrat : Le traitement est nécessaire à l'exécution des services que vous avez demandés (gestion des abonnements, paiements, support).</List.Item>
                            <List.Item>Consentement : Lorsque vous vous inscrivez sur notre Site ou acceptez l'utilisation de cookies analytiques, vous consentez à la collecte et à l'utilisation de vos données.</List.Item>
                            <List.Item>Intérêt légitime : Nous avons un intérêt légitime à améliorer nos services, analyser l'utilisation du Site et prévenir tout abus ou fraude.</List.Item>
                        </List>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">4. Partage des données</h3>
                        <p className="text-gray-500">
                            Nous ne vendons ni ne louons vos données personnelles à des tiers. Toutefois, vos données peuvent être partagées avec les prestataires suivants dans le cadre de la fourniture de nos services :
                        </p>
                        <List className="mt-2">
                            <List.Item>O2Switch : Hébergeur de notre Site. Les données que nous collectons peuvent être stockées sur leurs serveurs en France, conformément aux exigences de sécurité et de confidentialité.</List.Item>
                            <List.Item>Stripe : Fournisseur de services de paiement. Stripe collecte et traite vos données bancaires et de paiement. Vous pouvez consulter leur politique de confidentialité pour plus d’informations.</List.Item>
                        </List>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">5. Cookies</h3>
                        <p className="text-gray-500">
                            Nous utilisons des cookies sur notre Site pour améliorer votre expérience en ligne. Il existe deux types de cookies :
                        </p>
                        <List className="mt-2">
                            <List.Item>Cookies fonctionnels : Ces cookies sont essentiels au bon fonctionnement du Site (par exemple, pour vous permettre de naviguer entre les pages et d'utiliser nos services en ligne).</List.Item>
                            <List.Item>Cookies analytiques : Ces cookies nous aident à analyser la manière dont le Site est utilisé, afin d'améliorer son contenu et sa performance. Vous pouvez gérer vos préférences en matière de cookies via les paramètres de votre navigateur.</List.Item>
                        </List>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">6. Conservation des données</h3>
                        <p className="text-gray-500">
                            Nous conservons vos données personnelles uniquement pendant la durée nécessaire à l’accomplissement des finalités pour lesquelles elles ont été collectées. 
                            Par exemple, vos informations de paiement sont conservées par Stripe uniquement pour la durée de votre abonnement.
                        </p>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">7. Sécurisation des données</h3>
                        <p className="text-gray-500">
                            Nous mettons en place des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles contre tout accès non autorisé, toute divulgation, altération ou destruction.
                        </p>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">8. Vos droits</h3>
                        <p className="text-gray-500">
                            Conformément au RGPD, vous disposez des droits suivants concernant vos données personnelles :
                        </p>
                        <List>
                            <List.Item>Droit d'accès : Vous pouvez demander une copie des données personnelles que nous détenons à votre sujet.</List.Item>
                            <List.Item>Droit de rectification : Vous pouvez nous demander de corriger toute information personnelle incorrecte ou incomplète.</List.Item>
                            <List.Item>Droit à l'effacement : Vous pouvez demander la suppression de vos données personnelles, sous réserve des exigences légales.</List.Item>
                            <List.Item>Droit à la limitation du traitement : Vous pouvez demander que le traitement de vos données soit limité dans certaines situations.</List.Item>
                            <List.Item>Droit d'opposition : Vous pouvez vous opposer au traitement de vos données pour des raisons légitimes, sauf si nous avons un intérêt légitime à poursuivre le traitement.</List.Item>
                            <List.Item>Droit à la portabilité des données : Vous pouvez demander la transmission de vos données personnelles dans un format structuré, couramment utilisé et lisible par machine.</List.Item>
                        </List>
                        <p className="text-gray-500 mt-2">
                            Si vous souhaitez exercer vos droits, vous pouvez nous contacter par email à l’adresse suivante : [contact@planileo.fr].
                        </p>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">9. Modifications de cette politique de confidentialité</h3>
                        <p className="text-gray-500">
                            Nous nous réservons le droit de mettre à jour cette politique de confidentialité. Toute modification sera publiée sur cette page avec la date de la dernière mise à jour. Nous vous encourageons à consulter régulièrement cette page pour être informé des éventuelles modifications.
                        </p>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">10. Coordonnées</h3>
                        <p className="text-gray-500">
                            Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité ou la manière dont vos données personnelles sont traitées, n’hésitez pas à nous contacter :
                        </p>
                        
                        <p className="mt-6 mb-6 text-gray-500">Planileo - WIZZME SAS - SIREN 842234692</p>
                        <p className="mt-6 mb-6 text-gray-500">Vourey le 24 janvier 2025</p>

                        <p className="font-medium">
                            Cette politique est conçue pour assurer la transparence sur la manière dont nous collectons, utilisons et protégeons vos données personnelles, tout en respectant les lois en vigueur sur la protection des données en Europe.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default PolitiqueConfidentialite;