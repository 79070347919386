import React from 'react';
import { Sidebar, Drawer } from "flowbite-react";
import { HiOutlineCalendar, HiOutlineUserGroup, HiViewBoards, HiChartPie } from "react-icons/hi";
import { BiBuoy } from "react-icons/bi";


const SidebarMenu = ({ sidebarOpen, closeSidebar }) => {
    let width = window.innerWidth;
    return (
        <>
            {width > 1024 ? (
                <Sidebar className="hidden lg:block" aria-label="Default sidebar">
                    <Sidebar.Items>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item href="/form/tableau-de-bord" icon={HiChartPie}>
                                Tableau de bord
                            </Sidebar.Item>
                            <Sidebar.Item href="/form/mon-planning" icon={HiOutlineCalendar}>
                                Mon planning
                            </Sidebar.Item>
                            <Sidebar.Item href="/form/mes-organismes" icon={HiOutlineUserGroup}>
                                Organismes
                            </Sidebar.Item>
                        </Sidebar.ItemGroup>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item href="/documents/Tutoriel_planileo_formateurs.pdf" target="_blank" icon={HiViewBoards}>
                                Documentation
                            </Sidebar.Item>
                            <Sidebar.Item href="/form/aide" icon={BiBuoy}>
                                Besoin d'aide ?
                            </Sidebar.Item>
                        </Sidebar.ItemGroup>
                    </Sidebar.Items>
                </Sidebar>
            ) : (
                <Drawer className="lg:hidden" open={sidebarOpen} onClose={closeSidebar}>
                    <Drawer.Header title="MENU" titleIcon={() => <></>} />
                    <Drawer.Items>
                        <Sidebar aria-label="Default sidebar">
                            <Sidebar.Items>
                                <Sidebar.ItemGroup>
                                    <Sidebar.Item href="/form/mon-planning" icon={HiOutlineCalendar}>
                                        Mon planning
                                    </Sidebar.Item>
                                    <Sidebar.Item href="/form/mes-organismes" icon={HiOutlineUserGroup}>
                                        Organismes
                                    </Sidebar.Item>
                                </Sidebar.ItemGroup>
                                <Sidebar.ItemGroup>
                                    <Sidebar.Item href="/documents/Tutoriel_planileo_formateurs.pdf" target="_blank" icon={HiViewBoards}>
                                        Documentation
                                    </Sidebar.Item>
                                    <Sidebar.Item href="/form/aide" icon={BiBuoy}>
                                        Besoin d'aide ?
                                    </Sidebar.Item>
                                </Sidebar.ItemGroup>
                            </Sidebar.Items>
                        </Sidebar>
                    </Drawer.Items>
                </Drawer>
            )}
        </>
    );
}

export default SidebarMenu;