import React, { useEffect, useState } from 'react'
import Layout from "./global/Layout"

function FormHelp() {
    return (
        <Layout>
            <div className="pt-6 flex">
                <div>
                    <h3 className="mb-4 text-xl font-semibold">Besoin d'aide</h3>
                    <p>Vous rencontrez un bug ou un problème ? Contactez-nous à l'adresse <a className="underline" href="mailto:support@planileo.fr">support@planileo.fr</a>.</p>
                </div>
            </div>
        </Layout>
    );
}
export default FormHelp