import React, { useEffect, useState } from 'react'
import Layout from "./global/Layout"
import { useParams } from 'react-router-dom';
import { Label, Button, Modal, Textarea, Alert, Timeline } from "flowbite-react";
import timeGridPlugin from '@fullcalendar/timegrid'
import daygridPlugin from "@fullcalendar/daygrid"
import FullCalendar from '@fullcalendar/react'
import frLocale from '@fullcalendar/core/locales/fr'
import { HiDuplicate, HiCheck } from "react-icons/hi";
import AskUpdateModal from "./components/AskUpdateModal"
import { useNavigate } from 'react-router-dom'
import { parseSqlDate, getDaysArray } from '../../utils/utils';
import FilAriane from "../../components/FilAriane"


function FormPlanning() {
    const [formateur, setFormateur] = useState([]);
    const [options, setOptions] = useState([]);
    const [unavails, setUnavails] = useState([]);
    const [avails, setAvails] = useState([]);
    const [eventModal, setEventModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [askUpdateModal, setAskUpdateModal] = useState(false);
    const [updateSent, setUpdateSent] = useState(false);
    const [startTime, setStartTime] = useState("08:00");
    const [endTime, setEndTime] = useState("18:00");
    const [optionDate, setOptionDate] = useState("");
    const [optionTitre, setOptionTitre] = useState("");
    const [duplicateOptionDate, setDuplicateOptionDate] = useState("");
    const [comment, setComment] = useState("");
    const [privateComment, setPrivateComment] = useState("");
    const [updateComment, setUpdateComment] = useState("");
    const [updatePrivateComment, setUpdatePrivateComment] = useState("");
    const [savePublicCommentSuccess, setSavePublicCommentSuccess] = useState(false);
    const [savePrivateCommentSuccess, setSavePrivateCommentSuccess] = useState(false);
    const [currEvent, setCurrEvent] = useState([]);
    const navigate = useNavigate()

    // Calendar responsive
    var calendarInitial
    var views
    var calendarButtons
    if( window.innerWidth > 768 ) {
        views = ["dayGridMonth", "timeGridWeek", "timeGridDay"]
        calendarButtons = "dayGridMonth timeGridWeek timeGridDay"
        calendarInitial = "timeGridWeek"
    } else {
        views = {
            timeGridThreeDay: {
                type: 'timeGrid',
                duration: { days: 3 },
                buttonText: '3 jours'
            },
        }
        calendarButtons = "timeGridThreeDay timeGridDay"
        calendarInitial = "timeGridThreeDay"
    }

    const params = useParams();
    function closeUpdateModal () {
        setAskUpdateModal(false)
    }
    function updateIsSent () {
        setUpdateSent(true)
    }

    const getFormateur = async () => {
        const response = await fetch('https://api.planileo.fr/orgs/getFormateur/' + params.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const res = await response.json()
            setFormateur(res.formateur)
        }
    };

    const getUnavails = async () => {
        const response = await fetch('https://api.planileo.fr/planning/getUnavailabilities/' + params.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            var results = []
            const result = await response.json();
            for (const unavail of result.unavails) {
                results.push({ title: 'Indisponibilité', start: unavail.startdate, end: unavail.enddate, extendedProps: { type: "Indisponibilité", title: "Indisponibilité" }, backgroundColor: "#727272", borderColor: "#727272" })
            }
            setUnavails(results);
        }
    };
    const getAvails = async () => {
        const response = await fetch('https://api.planileo.fr/planning/getAvailabilities/' + params.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const results = await response.json();
            setAvails(results.avails)
            for (const avail of results.avails) {
                const daylist = getDaysArray(new Date(avail.startdate),new Date(avail.enddate));
                for (const day of daylist) {
                    const elem = document.querySelectorAll('.fc-timegrid-cols td.fc-timegrid-col[data-date="'+ day +'"]')
                    if( elem.length > 0 ) {
                        if( avail.weekends == 0 ) { // exclude weekends
                            if( !elem[0].classList.contains('fc-day-sat') && !elem[0].classList.contains('fc-day-sun') ) {
                                elem[0].classList.add('bg-green-300')
                            }
                        } else {
                            elem[0].classList.add('bg-green-300')
                        }
                    }
                }
            }
        }
    };
    const getOptions = async () => {
        const response = await fetch('https://api.planileo.fr/planning/getOptions/' + params.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            var results = []
            const result = await response.json();
            for (const option of result.options) {
                const formateur = option.formPrenom + ' ' + option.formNom;
                if (option.etat === 1) { //option validée formateur
                    results.push({ title: 'Option | Formateur : ' + formateur, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: option.title, status: 'valid_org', data: option }, backgroundColor: "#F8D21B", borderColor: "#F8D21B" })
                } else if (option.etat === 0) { //option posée
                    results.push({ title: 'Option | Formateur : ' + formateur, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: option.title, status: 'valid_form', data: option }, backgroundColor: "#F87F1B", borderColor: "#F87F1B" })
                } else { // option validée par l'organisme
                    results.push({ title: 'Formation | Formateur : ' + formateur, start: option.startdate, end: option.enddate, extendedProps: { type: "option", title: option.title, status: 'confirmed', data: option }, backgroundColor: "#88CA0B", borderColor: "#88CA0B" })
                }
            }
            setOptions(results);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            getFormateur();
            getUnavails();
            getOptions();
            getAvails();
        }
        fetchData()
    }, []);

    const addOption = async () => {
        if( !optionTitre ) {
            alert('Veuillez entrer un titre')
            return
        }
        const startDate = optionDate + "T" + startTime;
        const endDate = optionDate + "T" + endTime;
        const response = await fetch('https://api.planileo.fr/planning/addOption', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title: optionTitre,
                startDate: startDate,
                endDate: endDate,
                comment: comment,
                privateComment: privateComment,
                formId: params.id
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getOptions()
                setAddModal(false)
            } else {
                alert(result.message)
            }
            setStartTime("08:00")
            setEndTime("18:00")
        }
    }

    const duplicateOption = async () => {
        console.log(currEvent)
        var startDate = duplicateOptionDate + "T" + currEvent.data.startdate.split("T")[1];
        var endDate = duplicateOptionDate + "T" + currEvent.data.enddate.split("T")[1];
        
        const offset = new Date().getTimezoneOffset()
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        startDate = new Date(startDate.getTime() - (offset*60*1000))
        endDate = new Date(endDate.getTime() - (offset*60*1000))

        const response = await fetch('https://api.planileo.fr/planning/addOption', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                startDate: startDate.toISOString().slice(0, -8),
                endDate: endDate.toISOString().slice(0, -8),
                comment: currEvent.data.commentaire,
                title: currEvent.data.title,
                formId: currEvent.data.form_id
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getOptions()
                setDuplicateModal(false)
                setEventModal(false)
            } else {
                alert(result.message)
            }
        }
    }

    const deleteOption = async (sendMail) => {
        const response = await fetch('https://api.planileo.fr/planning/deleteOption/' + currEvent.data.id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mail: sendMail
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getOptions()
                setEventModal(false)
            } else {
                alert(result.message)
            }
        }
    }

    const acceptOption = async () => {
        const response = await fetch('https://api.planileo.fr/planning/acceptOption/' + currEvent.data.id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getOptions()
                setEventModal(false)
            } else {
                alert(result.message)
            }
        }
    }

    const saveComment = async (commentType) => {
        const response = await fetch('https://api.planileo.fr/planning/saveCommentOption/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                optionId: currEvent.data.id,
                commentType: commentType,
                publicComment: updateComment,
                privateComment: updatePrivateComment
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                getOptions()
                if(commentType === "public") {
                    setSavePublicCommentSuccess(true)
                    setTimeout(() => { setSavePublicCommentSuccess(false) }, 1200);
                } else {
                    setSavePrivateCommentSuccess(true)
                    setTimeout(() => { setSavePrivateCommentSuccess(false) }, 1200);
                }
            } else {
                alert('Une erreur est survenue')
            }
        }
    }

    function dayCellDidMount(day) {
        if( avails.length > 0) {
            for (const avail of avails) {
                const daylist = getDaysArray(new Date(avail.startdate),new Date(avail.enddate));
                for (const day of daylist) {
                    // week and day view
                    const elem = document.querySelectorAll('.fc-timegrid-cols td.fc-timegrid-col[data-date="'+ day +'"]:not(.bg-green-300)')
                    if( elem.length > 0 ) {
                        if( avail.weekends == 0 ) { // exclude weekends
                            if( !elem[0].classList.contains('fc-day-sat') && !elem[0].classList.contains('fc-day-sun') ) {
                                elem[0].classList.add('bg-green-300')
                            }
                        } else {
                            elem[0].classList.add('bg-green-300')
                        }
                    }
                    // month view
                    const dayCell = document.querySelectorAll('td.fc-daygrid-day[data-date="'+ day +'"]:not(.bg-green-300)')
                    if( dayCell.length > 0 ) {
                        if( avail.weekends == 0 ) { // exclude weekends
                            if( !dayCell[0].classList.contains('fc-day-sat') && !dayCell[0].classList.contains('fc-day-sun') ) {
                                dayCell[0].classList.add('bg-green-300')
                            }
                        } else {
                            dayCell[0].classList.add('bg-green-300')
                        }
                    }
                }
            }
        }
    }

    const eventClick = (el) => {
        setEventModal(true)
        setCurrEvent(el.event._def.extendedProps)
    };

    return (
        <Layout>
            <FilAriane firstItem={{ label: "Mes formateurs", url: "/org/mes-formateurs" }} secondItem={{ label: formateur.prenom + ' ' + formateur.nom, url: "" }} />
            <div className="mb-4 block md:flex md:items-center md:justify-between">
                <div className="mb-4 md:mb-0">
                    <h3 className="text-l font-semibold md:text-xl">Planning du formateur {formateur.prenom} {formateur.nom}</h3>
                    <p className="text-xs text-gray-600 md:text-lg">Vous pouvez poser des options sur ce planning. <br/>Les périodes privilégiées par le formateur apparaissent en vert.</p>
                </div>
                <div className="text-right flex flex-col items-end justify-end">
                    {updateSent === false ? (
                        <p className="mb-2 text-sm text-gray-600 underline cursor-pointer" onClick={() => setAskUpdateModal(true)}>Demander une mise à jour</p>
                    ) : (
                        <p className="mb-2 text-sm text-gray-600">Demande envoyée</p>
                    )}  
                    <Button gradientDuoTone="pinkToOrange" onClick={() => setAddModal(true)}>Ajouter une option</Button>
                </div>
            </div>
            <div className='pb-6'>
                <FullCalendar
                    plugins={[daygridPlugin, timeGridPlugin]}
                    editable
                    selectable
                    allDaySlot={false}
                    headerToolbar={{
                        start: "today prev next title",
                        end: calendarButtons,
                    }}
                    events={options.concat(unavails)}
                    slotMinTime={'06:00:00'}
                    slotMaxTime={'20:00:00'}
                    height={'auto'}
                    locale={frLocale}
                    dayCellDidMount={dayCellDidMount}
                    eventClick={eventClick}
                    views={views}
                    initialView={calendarInitial}
                />
            </div>
            <AskUpdateModal closeModal={closeUpdateModal} form={params.id} modalState={askUpdateModal} updateIsSent={updateIsSent} />
            <Modal show={addModal} onClose={() => setAddModal(false)}>
                <Modal.Header>Ajouter une option</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            <p className="font-small text-gray-600">Le formateur pourra accepter ou refuser ce créneau.</p>
                        </div>
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="optionTitre" value="Titre de la formation" />
                                </div>
                                <input type="text" className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                    id="optionTitre"
                                    placeholder="Titre de la formation"
                                    onChange={(e) => setOptionTitre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-2">
                                <div className="mb-2 block">
                                    <Label htmlFor="optionDate" value="Date de la formation" />
                                </div>
                                <input type="date" className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                    id="optionDate"
                                    onChange={(e) => setOptionDate(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-2">
                                <div className="mb-2 block">
                                    <Label htmlFor="startTime" value="Heure de début" />
                                </div>
                                <input type="time" defaultValue="08:00" className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                    id="startTime"
                                    onChange={(e) => setStartTime(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-2">
                                <div className="mb-2 block">
                                    <Label htmlFor="endTime" value="Heure de fin" />
                                </div>
                                <input type="time" defaultValue="18:00" className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                    id="endTime"
                                    onChange={(e) => setEndTime(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-2">
                                <div className="mb-2 block">
                                    <Label htmlFor="comment" value="Informations pour le formateur" />
                                </div>
                                <Textarea id="comment" placeholder="Lieu, nombre de stagiaires, etc.." onChange={(e) => setComment(e.target.value)} required rows={4} />
                            </div>
                            <div className="mt-2">
                                <div className="mb-2 block">
                                    <Label htmlFor="comment" value="Informations pour vous (privées)" />
                                </div>
                                <Textarea id="comment" placeholder="Le formateur ne verra pas ces informations" onChange={(e) => setPrivateComment(e.target.value)} required rows={4} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { addOption() }} type="submit">Ajouter</Button>
                </Modal.Footer>
            </Modal>
            <Modal dismissible show={eventModal} onClose={() => setEventModal(false)}>
                <Modal.Header>
                    <div className="flex items-center">
                        {currEvent.title ? ( currEvent.title ) : ( <p>Option</p> )} 
                        <HiDuplicate className="ml-2 cursor-pointer" onClick={() => { setDuplicateModal(true) }}/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            {currEvent.type === "option" &&
                                <div>
                                    {currEvent.status === "valid_form" &&
                                        <Alert color="warning" rounded>Le formateur <span className="font-medium">{currEvent.data.formPrenom} {currEvent.data.formNom}</span> n'a pas encore accepté l'option.</Alert>
                                    }
                                    {currEvent.status === "valid_org" &&
                                            <Alert color="warning" rounded>Le formateur a validé ce créneau de formation le {parseSqlDate(currEvent.data.valid_form_date)}.<br />En attente de votre validation finale.</Alert>
                                    }
                                    {currEvent.status === "confirmed" &&
                                        <>
                                            <Alert color="success" rounded>Une formation avec <span className="font-medium">{currEvent.data.formPrenom} {currEvent.data.formNom}</span> est prévue sur ce créneau</Alert>
                                            <Timeline className="mt-4">
                                                <Timeline.Item>
                                                    <Timeline.Point />
                                                    <Timeline.Content>
                                                        <Timeline.Time>{parseSqlDate(currEvent.data.creation_date)}</Timeline.Time>
                                                        <Timeline.Title>Option posée</Timeline.Title>
                                                        <Timeline.Body>
                                                            Par l'organisme de formation
                                                        </Timeline.Body>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                                <Timeline.Item>
                                                    <Timeline.Point />
                                                    <Timeline.Content>
                                                        <Timeline.Time>{parseSqlDate(currEvent.data.valid_form_date)}</Timeline.Time>
                                                        <Timeline.Title>Option validée côté formateur</Timeline.Title>
                                                        <Timeline.Body>
                                                            {currEvent.data.formPrenom} {currEvent.data.formNom}
                                                        </Timeline.Body>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                                <Timeline.Item>
                                                    <Timeline.Point />
                                                    <Timeline.Content>
                                                        <Timeline.Time>{parseSqlDate(currEvent.data.valid_org_date)}</Timeline.Time>
                                                        <Timeline.Title>Validation finale</Timeline.Title>
                                                        <Timeline.Body>
                                                            Par l'organisme de formation
                                                        </Timeline.Body>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                            </Timeline>
                                        </>
                                    }
                                    <div className="mt-4 mb-2 block flex items-center">
                                        <Label htmlFor="commentaire" value="Commentaire à l'attention du formateur" />
                                        <Button className="ml-2" color="light" size="xs" onClick={() => { saveComment("public") }}>Enregistrer</Button>
                                        
                                        {savePublicCommentSuccess &&
                                            <div className="ml-2 inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                                                <HiCheck className="h-4 w-4" />
                                            </div>
                                        }
                                    </div>
                                    <Textarea id="public_commentaire" placeholder="Pas de commentaire pour l'instant" onChange={(e) => setUpdateComment(e.target.value)} defaultValue={currEvent.data.commentaire} rows={4} />
                                    <div className="mt-4 mb-2 block flex items-center">
                                        <Label htmlFor="commentaire" value="Commentaire privé" />
                                        <Button className="ml-2" color="light" size="xs" onClick={() => { saveComment("private") }}>Enregistrer</Button>
                                        
                                        {savePrivateCommentSuccess &&
                                            <div className="ml-2 inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                                                <HiCheck className="h-4 w-4" />
                                            </div>
                                        }
                                    </div>
                                    <Textarea id="private_commentaire" placeholder="Pas de commentaire pour l'instant" onChange={(e) => setUpdatePrivateComment(e.target.value)} defaultValue={currEvent.data.commentaire_prive} rows={4} />
                                </div>
                            }
                            {currEvent.type === "Indisponibilité" && <p className="font-small text-gray-600">Le formateur n'est pas disponible sur ce créneau ce créneau.</p>}
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {currEvent.type === "option" ? (
                        <>
                            {currEvent.status === "valid_org" && (
                                <>
                                    <Button onClick={() => { setEventModal(false); acceptOption() }}>Valider le créneau de formation</Button>
                                    <Button color="failure" onClick={() => { setEventModal(false); deleteOption(true) }}>Supprimer l'option</Button>
                                </>
                            )}
                            {currEvent.status === "valid_form" && <Button color="failure" onClick={() => { setEventModal(false); deleteOption(false) }}>Supprimer l'option</Button>}
                            {currEvent.status === "confirmed" && <Button color="failure" onClick={() => { setEventModal(false); deleteOption(true) }}>Supprimer l'option</Button>}

                        </>
                    ) : (
                        <Button onClick={() => setEventModal(false)}>Fermer</Button>
                    )}
                </Modal.Footer>
                <Modal show={duplicateModal} size="md" onClose={() => setDuplicateModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div>
                        <p className="mb-2 text-md font-normal text-gray-500 dark:text-gray-400">
                            A quelle date voulez-vous dupliquer cette option ?
                        </p>
                        <input type="date" className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                    id="optionDate"
                                    onChange={(e) => setDuplicateOptionDate(e.target.value)}
                                    required
                                />
                        <div className="mt-4 flex justify-center gap-4">
                            <Button color="blue" onClick={() => {setDuplicateModal(false); duplicateOption()}}>
                                {"Confirmer"}
                            </Button>
                            <Button color="gray" onClick={() => setDuplicateModal(false)}>
                                Annuler
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            </Modal>
        </Layout>
    );
}
export default FormPlanning;