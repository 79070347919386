import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from "flowbite-react";
import { useNavigate } from 'react-router-dom'
import Layout from "./global/Layout";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { parseSqlDateRange } from '../../utils/utils';

function FormDashboard() {
    let [options, setOptions] = useState(false);
    let [nextOptions, setNextOptions] = useState(false);
    let [relations, setRelations] = useState(false);
    let [selectedRelation, setSelectedRelation] = useState(false);
    const [openRefuseModal, setOpenRefuseModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getOptions()
        getRelations()
        getNextOptions()
    }, []);
    
    const getNextOptions = async () => {
        const response = await fetch('https://api.planileo.fr/forms/getNextOptions', {
            method: 'GET',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue. Veuillez réessayer plus tard.')
        } else {
            const result = await response.json();
            setNextOptions(result.data);
        }
    };
    const getOptions = async () => {
        const response = await fetch('https://api.planileo.fr/forms/getAwaitingOptions', {
            method: 'GET',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue. Veuillez réessayer plus tard.')
        } else {
            const result = await response.json();
            setOptions(result.data);
        }
    };
    const acceptOption = async (id) => {
        const response = await fetch('https://api.planileo.fr/planning/acceptOption/' + id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                window.location.reload()
            } else {
                alert(result.message)
            }
        }
    }

    const getRelations = async () => {
        const response = await fetch('https://api.planileo.fr/forms/getAwaitingRelations', {
            method: 'GET',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue. Veuillez réessayer plus tard.')
        } else {
            const result = await response.json();
            setRelations(result.data);
        }
    }
    const confirmRelation = async (id) => {
        console.log(id)
        const response = await fetch('https://api.planileo.fr/relations/confirmRelation/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                org: id,
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            console.log('Une erreur est survenue. Veuillez réessayer plus tard.');
        } else {
            window.location.reload()
        }
    }
    const refuseRelation = async () => {
        console.log(selectedRelation)
        const response = await fetch('https://api.planileo.fr/relations/refuseRelation/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                org: selectedRelation,
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            console.log('Une erreur est survenue. Veuillez réessayer plus tard.');
        } else {
            window.location.reload()
        }
    }

    return (
        <Layout>
            <div className="pt-6 flex">
                <div>
                    <h3 className="text-xl font-semibold">Tableau de bord</h3>
                    <p>Bienvenue dans votre espace formateur</p>
                </div>
            </div>
            <div className="mt-6 xl:flex">
                <div className="xl:w-3/5">
                    <h3 className="text-l font-semibold">Prochains créneaux de formation</h3>
                    <p className="text-gray-500 text-sm">Vous pouvez voir toutes vos formations dans <a className="underline" href="/form/mon-planning">votre planning</a>.</p>
                    {nextOptions && nextOptions.length === 0 ? (
                        <p className="font-small text-gray-600 mt-2">Pas de formations prévues pour l'instant</p>
                    ) : (
                        <Table className="mt-2">
                            <Table.Head className="sm:contents hidden">
                                <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Entreprise</Table.HeadCell>
                                <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Titre</Table.HeadCell>
                                <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Date de la formation</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {nextOptions && nextOptions.length > 0 && nextOptions.map((option, index) => (
                                    <Table.Row key={index} className="flex flex-col sm:table-row bg-white min-w-[320] dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                            {option.entreprise}
                                        </Table.Cell>
                                        <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                            {option.title}
                                        </Table.Cell>
                                        <Table.Cell className="px-2 py-2 text-small md:font-medium md:px-6 md:py-4">{parseSqlDateRange(option.startdate, option.enddate)}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </div>
                <div className="mt-8 xl:mt-0 xl:ml-8 xl:w-2/5">
                    <h3 className="text-l font-semibold">Demandes de relation</h3>
                    <p className="text-gray-500 text-sm">Vous pouvez voir toutes vos relations dans <a className="underline" href="/form/mon-planning">vos formateurs</a>.</p>
                    {relations && relations.length === 0 ? (
                        <p className="font-small text-gray-600 mt-2">Pas de relation en attente pour l'instant</p>
                    ) : (
                        <Table className="mt-2">
                            <Table.Head className="sm:contents hidden">
                                <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Entreprise</Table.HeadCell>
                                <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                                    <span className="sr-only">Actions</span>
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {relations && relations.length > 0 && relations.map((relation, index) => (
                                    <Table.Row key={index} className="flex flex-col sm:table-row bg-white min-w-[320] dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                            {relation.entreprise}
                                        </Table.Cell>
                                        <Table.Cell className="px-2 py-2 font-small md:font-medium md:px-6 flex items-center md:py-4">
                                            <Button size="sm" color="blue" onClick={() => {confirmRelation(relation.id)} }>Accepter la relation</Button>
                                            <p className="ml-2 text-sm text-red-600 underline cursor-pointer" onClick={() => {setSelectedRelation(relation.id); setOpenRefuseModal(true)} }>Refuser</p>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </div>
            </div>
            <div className="mt-6 pb-8">
                <h3 className="text-l font-semibold">Options à valider</h3>
                <p className="text-gray-500 text-sm">Vous pouvez voir toutes vos options dans <a className="underline" href="/form/mon-planning">votre planning</a>.</p>
                {options && options.length === 0 ? (
                    <p className="font-small text-gray-600 mt-2">Pas d'options à valider pour l'instant</p>
                ) : (
                    <Table className="mt-2">
                        <Table.Head className="sm:contents hidden">
                            <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Entreprise</Table.HeadCell>
                            <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Titre</Table.HeadCell>
                            <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Date de la formation</Table.HeadCell>
                            <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                                <span className="sr-only">Action</span>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {options && options.length > 0 && options.map((option, index) => (
                                <Table.Row key={index} className="flex flex-col sm:table-row bg-white min-w-[320] dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                        {option.entreprise}
                                    </Table.Cell>
                                    <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                        {option.title}
                                    </Table.Cell>
                                    <Table.Cell className="px-2 py-2 text-small md:font-medium md:px-6 md:py-4">{parseSqlDateRange(option.startdate, option.enddate)}</Table.Cell>
                                    <Table.Cell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                                        <Button size="sm" color="blue" onClick={() => {acceptOption(option.id)} }>Confirmer l'option</Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </div>
            <Modal show={openRefuseModal} size="md" onClose={() => setOpenRefuseModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Etes-vous sur de vouloir refuser cette demande ?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => {setOpenRefuseModal(false); refuseRelation()}}>
                                {"Confirmer"}
                            </Button>
                            <Button color="gray" onClick={() => setOpenRefuseModal(false)}>
                                Annuler
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    );
}
export default FormDashboard;