import React, { useEffect, useState } from 'react';
import Layout from "./global/Layout";
import { useNavigate } from 'react-router-dom'
import { Table, Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import FilAriane from "../../components/FilAriane";

function FormOrganismes() {
    let [organismes, setOrganismes] = useState(false);
    let [selectedOrg, setSelectedOrg] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openRefuseModal, setOpenRefuseModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getOrganismes();
    }, []);

    const getOrganismes = async () => {
        const response = await fetch('https://api.planileo.fr/forms/getFormOrganismes', {
            method: 'GET',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue.')
        } else {
            const result = await response.json();
            setOrganismes(result.data);
        }
    };

    const confirmRelation = async (orgId) => {
        console.log(orgId)
        const response = await fetch('https://api.planileo.fr/relations/confirmRelation/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                org: orgId,
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            console.log('Une erreur est survenue. Veuillez réessayer plus tard.');
        } else {
            window.location.reload()
        }
    }

    const refuseRelation = async () => {
        console.log(selectedOrg)
        const response = await fetch('https://api.planileo.fr/relations/refuseRelation/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                org: selectedOrg,
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            console.log('Une erreur est survenue. Veuillez réessayer plus tard.');
        } else {
            window.location.reload()
        }
    }

    const deleteRelation = async () => {
        const response = await fetch('https://api.planileo.fr/relations/deleteFormRelation/' + selectedOrg, {
            method: 'POST',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            console.log('Une erreur est survenue. Veuillez réessayer plus tard.');
        } else {
            window.location.reload()
        }
    }


    return (
        <Layout>
            <FilAriane firstItem={{label:"Organismes", url:"/form/mes-organismes"}} />
            <div className="flex items-center justify-between">
                <div>
                    <h3 className="text-l font-semibold md:text-xl">Liste des organismes de formation ajoutés</h3>
                    <p className="text-sm text-gray-600 md:text-lg">Ces organismes peuvent visualiser votre planning et poser des options dessus.</p>
                </div>
            </div>
            <div className="mt-4 overflow-x-auto">
                <Table>
                    <Table.Head className="sm:contents hidden">
                        <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Entreprise</Table.HeadCell>
                        <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Email</Table.HeadCell>
                        <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                            <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {organismes && organismes.length > 0 && organismes.map((organisme, index) => (
                            <Table.Row key={index} className="flex flex-col sm:table-row bg-white min-w-[320] dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                    {organisme.entreprise}
                                </Table.Cell>
                                <Table.Cell className="px-2 py-2 text-small md:font-medium md:px-6 md:py-4">{organisme.email}</Table.Cell>
                                <Table.Cell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                                    {organisme.status === 'pending' ?(
                                        <div className="flex items-center justify-start md:justify-end md:border-">
                                            <Button size="sm" color="blue" onClick={() => {confirmRelation(organisme.id)} }>Accepter la demande</Button>
                                            <p className="ml-2 text-sm text-red-600 underline cursor-pointer" onClick={() => {setSelectedOrg(organisme.id); setOpenRefuseModal(true)} }>Refuser</p>
                                        </div>
                                    ) : (
                                        <Button size="sm" color="failure" onClick={() => {setOpenDeleteModal(true); setSelectedOrg(organisme.id)} }>Supprimer</Button>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                {organismes && organismes.length === 0 &&
                    <p className="font-small text-gray-600 ml-4 mt-2">Pas d'organismes pour l'instant</p>
                }
            </div>
            <Modal show={openDeleteModal} size="md" onClose={() => setOpenDeleteModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Etes-vous sur de vouloir supprimer cet organisme de vos relations ? Il ne pourra plus visualiser votre planning ni poser d'options dessus.
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => {setOpenDeleteModal(false); deleteRelation()}}>
                                {"Confirmer"}
                            </Button>
                            <Button color="gray" onClick={() => setOpenDeleteModal(false)}>
                                Annuler
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openRefuseModal} size="md" onClose={() => setOpenRefuseModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Etes-vous sur de vouloir refuser cette demande ?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => {setOpenRefuseModal(false); refuseRelation()}}>
                                {"Confirmer"}
                            </Button>
                            <Button color="gray" onClick={() => setOpenRefuseModal(false)}>
                                Annuler
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    );
}
export default FormOrganismes;