import React, { useEffect, useState } from 'react';
import Layout from "./global/Layout";
import { Button, Label, TextInput } from "flowbite-react";
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

function OrgMyAccount() {
    const [cookies] = useCookies(['userInfo'])
    const [user, setUser] = useState([])
    const [nom, setNom] = useState("")
    const [prenom, setPrenom] = useState("")
    const [abonnementUrl, setAbonnementUrl] = useState("")
    const [selectedPlan, setSelectedPlan] = useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        getUserInfo();
    }, []);

    let handleSubmit = async () => {
        if (nom !== "" && prenom !== "") {
            try {
                let res = await fetch("https://api.planileo.fr/users/updateUser", {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: cookies.userInfo.id,
                        nom: nom,
                        prenom: prenom
                    }),
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.success === true) {
                        alert("Données enregistrées")
                    } else {
                        alert("Une erreur est survenue. Veuillez réessayer plus tard.")
                    }
                } else {
                    alert("Une erreur est survenue. Veuillez réessayer plus tard.")
                }
            } catch (err) {
                alert("Impossible de contacter le serveur. Veuillez réessayer plus tard.");
            }
        } else {
            alert("Veuillez renseigner votre nom et prénom");
        }
    };

    const abonnementClick = () => {
        console.log(selectedPlan)
        if( selectedPlan === 3 ) { // gold
            window.location.href = ("https://buy.stripe.com/bIY4glavrdFD6SAcMO?prefilled_email=" + user.email )
        } else if (selectedPlan === 2) { // silver
            window.location.href = ("https://buy.stripe.com/9AQfZ31YV597fp6145?prefilled_email=" + user.email )
        } else if (selectedPlan === 1) { // bronze
            window.location.href = ("https://buy.stripe.com/4gw9AFdHDfNLdgY6oo?prefilled_email=" + user.email )
        } 
    }

    const getUserInfo = async () => {
        const response = await fetch('https://api.planileo.fr/users/getUserInfo/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const res = await response.json()
            setAbonnementUrl("https://billing.stripe.com/p/login/eVa6pB8s29Tv97q144?prefilled_email=" + res.email)
            setUser(res)
            setNom(res.nom)
            setPrenom(res.prenom)
        }
    };

    return (
        <Layout>
            <div className="pb-8">
                <h3 className="text-xl font-semibold">Mon compte</h3>
                <p className="font-small text-gray-600">Vous pouvez consulter et modifier vos informations ci-dessous</p>
            
                <div className="mt-4 flex max-w-md flex-col gap-4">
                    <div>
                        <Label htmlFor="disabledInput2">Adresse email</Label>
                        <TextInput type="text" defaultValue={user.email} disabled readOnly />
                    </div>
                    <div>
                        <Label htmlFor="disabledInput2">Type de compte</Label>
                        <TextInput type="text" defaultValue={user.role} disabled readOnly />
                    </div>
                    <div>
                        <Label htmlFor="disabledInput2">Nom</Label>
                        <TextInput type="text" defaultValue={user.nom} onChange={(e) => setNom(e.target.value)} placeholder="Votre nom" />
                    </div>
                    <div>
                        <Label htmlFor="disabledInput2">Prénom</Label>
                        <TextInput type="text" defaultValue={user.prenom} onChange={(e) => setPrenom(e.target.value)} placeholder="Votre prénom" />
                    </div>
                </div>
                {nom === user.nom && prenom === user.prenom ? (
                    <Button className="mt-4" disabled>Enregistrer</Button>
                ) : (
                    <Button onClick={() => {handleSubmit(true)}} className="mt-4">Enregistrer</Button>
                )}

                <h3 className="mt-8 text-xl font-semibold">Abonnement</h3>
                {user.plan === 0 ? (
                    <>
                        <p className="font-small text-gray-600">Vous avez actuellement l'abonnement gratuit. Abonnez-vous à Planileo avec l'un des plans suivants</p>
                        <div className="mt-4 sm:space-x-4 sm:flex flew-wrap max-w-2xl">
                            <div className={selectedPlan === 1 ? 'border-2 border-blue-500 rounded-lg w-full' : 'border-2 border-white w-full'} onClick={() => {setSelectedPlan(1) }}>
                                <div className="relative mr-2 flex flex-col p-4 w-full text-sm text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow cursor-pointer hover:bg-gray-50">
                                    {selectedPlan === 1 ? (
                                        <h3 className="absolute left-2 top-1 top-0 text-blue-500 font-semibold">Bronze</h3>
                                    ) : (
                                        <h3 className="absolute left-2 top-1 top-0 text-gray-500 font-semibold">Bronze</h3>
                                    )}
                                    <div className="flex justify-center items-baseline mt-4 mb-2">
                                        <span className="mr-1 text-4xl font-extrabold">49€</span>
                                        <span className="text-gray-500 dark:text-gray-400">/mois</span>
                                    </div>
                                    <p>Jusqu'à 20 formateurs</p>
                                </div>
                            </div>
                            <div className={selectedPlan === 2 ? 'border-2 border-blue-500 rounded-lg w-full' : 'border-2 border-white w-full'} onClick={() => {setSelectedPlan(2) }}>
                                <div className="relative w-1/2 flex flex-col p-4 w-full text-sm text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow cursor-pointer hover:bg-gray-50">
                                    {selectedPlan === 2 ? (
                                        <h3 className="absolute left-2 top-1 top-0 text-blue-500 font-semibold">Argent</h3>
                                    ) : (
                                        <h3 className="absolute left-2 top-1 top-0 text-gray-500 font-semibold">Argent</h3>
                                    )}
                                    <div className="flex justify-center items-baseline mt-4 mb-2">
                                        <span className="mr-1 text-4xl font-extrabold">99€</span>
                                        <span className="text-gray-500 dark:text-gray-400">/mois</span>
                                    </div>
                                    <p>Jusqu'à 50 formateurs</p>
                                </div>
                            </div>
                            <div className={selectedPlan === 3 ? 'border-2 border-blue-500 rounded-lg w-full' : 'border-2 border-white w-full'} onClick={() => {setSelectedPlan(3) }}>
                                <div className="relative w-1/2 flex flex-col p-4 w-full text-sm text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow cursor-pointer hover:bg-gray-50">
                                {selectedPlan === 3 ? (
                                        <h3 className="absolute left-2 top-1 top-0 text-blue-500 font-semibold">Or</h3>
                                    ) : (
                                        <h3 className="absolute left-2 top-1 top-0 text-gray-500 font-semibold">Or</h3>
                                    )}
                                    <div className="flex justify-center items-baseline mt-4 mb-2">
                                        <span className="mr-1 text-4xl font-extrabold">149€</span>
                                        <span className="text-gray-500 dark:text-gray-400">/mois</span>
                                    </div>
                                    <p>Formateurs illimités</p>
                                </div>
                            </div>
                        </div>
                        <Button onClick={abonnementClick} className="mt-4 inline-block">Je m'abonne</Button>
                    </>
                ) : user.plan === 99 ? (
                    <>
                        <p className="font-small text-gray-600">Vous avez un plan spécial. Vous pouvez ajouter autant de formateurs que vous voulez sans abonnement.</p>
                    </>
                ) : (
                    <>
                        <p className="font-small text-gray-600">Gérez votre abonnement sur Stripe avec votre adresse email ({user.email}) en cliquant sur le bouton ci-dessous.</p>
                        <Button href={abonnementUrl} target="_blank" className="mt-4 inline-block">Mon abonnement</Button>
                    </>
                )}

                
            </div>
        </Layout>
    );
}
export default OrgMyAccount;