import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiInformationCircle, HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { Button, Checkbox, Radio, Label, TextInput, Alert } from "flowbite-react";
import { useNavigate } from 'react-router-dom'


const RegisterForm = () => {
    const [email, setEmail] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [company, setCompany] = useState("");
    const [password, setPassword] = useState("");
    const [displayPassword, setDisplayPassword] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState("");
    const [role, setRole] = useState("2");
    const [selectedPlan, setSelectedPlan] = useState(1);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    let handleSubmit = async (e) => {
        e.preventDefault();
        if (password === repeatPassword) {
            try {
                let res = await fetch("https://api.planileo.fr/users/register", {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password,
                        role: role,
                        plan: selectedPlan,
                        entreprise: company,
                        nom: nom,
                        prenom: prenom
                    }),
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.success === true) {
                        console.log(resJson)
                        if( resJson.userRole === 3 || resJson.userPlan === 0 ) {
                            navigate('/login', { state:{justRegistered : true}} )
                        } else if (resJson.userPlan === 1 ) { // bronze
                            window.location.href = ("https://buy.stripe.com/4gw9AFdHDfNLdgY6oo?prefilled_email=" + resJson.userEmail )
                        } else if (resJson.userPlan === 2) { // silver
                            window.location.href = ("https://buy.stripe.com/9AQfZ31YV597fp6145?prefilled_email=" + resJson.userEmail )
                        } else { // gold
                            window.location.href = ("https://buy.stripe.com/bIY4glavrdFD6SAcMO?prefilled_email=" + resJson.userEmail )
                        }
                    } else {
                        setIsError(resJson.message);
                    }
                } else {
                    setIsError("Une erreur ("+ res.status +") est survenue. Veuillez réessayer plus tard.");
                }
            } catch (err) {
                setIsError("Impossible de contacter le serveur. Veuillez réessayer plus tard.");
            }
        } else {
            setIsError("Les deux mots de passe ne sont pas égaux");
        }
    }

    const togglePassword = () => {
        if (displayPassword === true){
            setDisplayPassword(false)
        } else {
           setDisplayPassword(true)
        }
     }

    return (
            <form onSubmit={handleSubmit} className="md:flex px-4 justify-center mx-auto w-full max-w-screen-lg">
                <div className="md:mr-8 flex w-full flex-col gap-4">
                    <Alert className={`${!isError && "hidden"}`} color="failure" icon={HiInformationCircle}>
                        <span className="font-medium">Erreur</span> {isError}
                    </Alert>
                    <div>
                        <fieldset className="flex max-w-md flex-col gap-4">
                            <Label htmlFor="Role" value="Je m'inscris en tant que" />
                            <div className="flex items-center gap-2">
                                <Radio id="role-2" name="countries" value="2" defaultChecked onChange={e => { setRole(e.target.value) }} />
                                <Label htmlFor="united-state">Organisme de formation</Label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Radio id="role-3" name="countries" value="3" onChange={e => { setRole(e.target.value) }} />
                                <Label htmlFor="germany">Formateur</Label>
                            </div>
                        </fieldset>
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="company" value="Entreprise" />
                        </div>
                        <TextInput id="company" onChange={(e) => setCompany(e.target.value)} type="text" placeholder="Nom de l'entreprise" required shadow />
                    </div>
                    {role === "3" && (
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="nom" value="Nom" />
                            </div>
                            <TextInput id="nom" onChange={(e) => setNom(e.target.value)} type="text" placeholder="Nom" required shadow />

                            <div className="mb-2 mt-4 block">
                                <Label htmlFor="prenom" value="Prénom" />
                            </div>
                            <TextInput id="prenom" onChange={(e) => setPrenom(e.target.value)} type="text" placeholder="Prénom" required shadow />
                        </div>
                    )}
                    {role === "2" && (
                        <div>
                            <div className="mb-2">
                                <Label value="Votre abonnement" />
                            </div>
                            <div className="space-x-4 flex flew-wrap">
                                <div className={selectedPlan === 0 ? 'border-2 border-blue-500 rounded-lg w-full' : 'border-2 border-white w-full'} onClick={() => {setSelectedPlan(0) }}>
                                    <div className="relative mr-2 flex flex-col p-4 w-full h-full text-sm text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow cursor-pointer hover:bg-gray-50">
                                        <h3 className="absolute left-2 top-1 top-0 text-gray-500 font-semibold">Gratuit</h3>
                                        <div className="flex justify-center items-baseline mt-4 mb-2">
                                            <span className="mr-1 text-xl md:text-4xl font-extrabold">0€</span>
                                            <span className="text-gray-500 dark:text-gray-400">/an</span>
                                        </div>
                                        <p>Jusqu'à 5 formateurs</p>
                                    </div>
                                </div>
                                <div className={selectedPlan === 1 ? 'border-2 border-blue-500 rounded-lg w-full' : 'border-2 border-white w-full'} onClick={() => {setSelectedPlan(1) }}>
                                    <div className="relative mr-2 flex flex-col p-4 w-full h-full text-sm text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow cursor-pointer hover:bg-gray-50">
                                        <h3 className="absolute left-2 top-1 top-0 text-gray-500 font-semibold">Bronze</h3>
                                        <div className="flex justify-center items-baseline mt-4 mb-2">
                                            <span className="mr-1 text-xl md:text-4xl font-extrabold">49€</span>
                                            <span className="text-gray-500 dark:text-gray-400">/mois</span>
                                        </div>
                                        <p>Jusqu'à 20 formateurs</p>
                                    </div>
                                </div>
                            </div>
                            <div className="space-x-4 mt-4 flex flew-wrap">
                                <div className={selectedPlan === 2 ? 'border-2 border-blue-500 rounded-lg w-full' : 'border-2 border-white w-full'} onClick={() => {setSelectedPlan(2) }}>
                                    <div className="relative w-1/2 flex flex-col p-4 w-full h-full text-sm text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow cursor-pointer hover:bg-gray-50">
                                        <h3 className="absolute left-2 top-1 top-0 text-gray-500 font-semibold">Argent</h3>
                                        <div className="flex justify-center items-baseline mt-4 mb-2">
                                            <span className="mr-1 text-xl md:text-4xl font-extrabold">99€</span>
                                            <span className="text-gray-500 dark:text-gray-400">/mois</span>
                                        </div>
                                        <p>Jusqu'à 50 formateurs</p>
                                    </div>
                                </div>
                                <div className={selectedPlan === 3 ? 'border-2 border-blue-500 rounded-lg w-full' : 'border-2 border-white w-full'} onClick={() => {setSelectedPlan(3) }}>
                                    <div className="relative w-1/2 flex flex-col p-4 w-full h-full text-sm text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow cursor-pointer hover:bg-gray-50">
                                        <h3 className="absolute left-2 top-1 top-0 text-gray-500 font-semibold">Or</h3>
                                        <div className="flex justify-center items-baseline mt-4 mb-2">
                                            <span className="mr-1 text-xl md:text-4xl font-extrabold">149€</span>
                                            <span className="text-gray-500 dark:text-gray-400">/mois</span>
                                        </div>
                                        <p>Formateurs illimités</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-4 md:mt-0 md:ml-4 flex w-full flex-col gap-4">
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="email" value="Adresse email" />
                        </div>
                        <TextInput id="email" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="nom@exemple.com" required shadow />
                        {role === "2" && (
                            <> 
                            <p className="text-gray-500 text-xs">Cette adresse email servira comme identifiant de connexion pour tous les membres de votre organisme. Veillez à mettre une adresse générale.</p>
                            {selectedPlan !== 0 && <p className="text-red-500 text-xs">Laissez la même adresse email dans le formulaire de paiement.</p>}
                            </>
                        )}                            
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="password" value="Mot de passe" />
                        </div>
                        <div className="relative">
                            <TextInput id="password" onChange={(e) => setPassword(e.target.value)} type={displayPassword === false ? ("password") : ( "text" )} autoComplete="on" required shadow />
                            <span class="absolute right-2 bottom-[9px] cursor-pointer" onClick={togglePassword}>
                                {displayPassword === false ? ( <HiOutlineEye size={20} /> ) : ( <HiOutlineEyeOff size={20}/> )}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="repeat-password" value="Confirmation du mot de passe" />
                        </div>
                        <div className="relative">
                            <TextInput id="repeat-password" onChange={(e) => setRepeatPassword(e.target.value)} type={displayPassword === false ? ("password") : ( "text" )} autoComplete="on" required shadow />
                            <span class="absolute right-2 bottom-[9px] cursor-pointer" onClick={togglePassword}>
                                {displayPassword === false ? ( <HiOutlineEye size={20} /> ) : ( <HiOutlineEyeOff size={20}/> )}
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <Checkbox id="agree" required />
                        <Label htmlFor="agree" className="flex">
                            J'ai lu et j'accepte la&nbsp;
                            <Link href="/politique-de-confidentialite" className="text-cyan-600 hover:underline dark:text-cyan-500">
                                politique de confidentialité
                            </Link>
                        </Label>
                    </div>
                    <div>
                        <div className="mb-1 flex items-center gap-2">
                            <p className="text-sm">Déjà un compte ? <a className="underline" href="/login">Connexion</a></p>
                        </div>
                        <Button className="w-full" type="submit">Créer mon compte</Button>
                    </div>
                </div>
            </form>
    );
}
export default RegisterForm;