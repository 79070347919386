import React, { useEffect, useState } from 'react';
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";

function ConditionsVente() {

    useEffect(() => {
        
    }, []);


    return (
        <div>
            <Menu displayButtons={true} />
            <div className="w-full px-4 py-12 pb-4 flex justify-center">
                <div className="max-w-screen-xl">
                    <h3 className="text-xl font-semibold">CONDITIONS GENERALES DE VENTE SAS WIZZME</h3>
                    <div className="mt-4">
                        <h3 className="mb-2 text-xl font-bold dark:text-white">Article 1er Champ d’application</h3>
                        <p className="text-gray-500">
                            Les présentes Conditions Générales de Vente s’appliquent à toutes les prestations de services conclues par la Société WIZZME –
                            propriétaire sur site internet de services planileo.fr - auprès des clients professionnels de même catégorie, quelque soit les clauses
                            pouvant figurer sur les documents du Client, notamment ses conditions générales d’achat.
                            Conformément à la règlementation en vigueur, ces Conditions Générales de Vente sont systématiquement communiquées à tout
                            client qui en fait la demande, pour lui permettre de passer commande auprès de la Société WIZZME .
                            Conformément à la règlementation en vigueur, la Société WIZZME se réserve le droit de déroger à certaines clauses des présentes
                            Conditions Générales de Vente, en fonction des négociations menées avec le Client, par l’établissement de Conditions de Vente
                            Particulières.
                        </p>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">Article 2 Commande</h3>
                        <p className="text-gray-500">
                            <span className="font-medium text-gray-900">2-1</span> - Les ventes de prestations ne sont parfaites qu’après validation par le client d’une offre sur le site internet planileo.fr
                            Sauf convention particulière, la commande entraîne pour le Client acceptation des conditions de vente de la Société WIZZME , la
                            reconnaissance d’en avoir parfaitement connaissance et la renonciation à se prévaloir de ses propres conditions d’achat.
                            Le bénéfice de la commande est personnel à l’acheteur et ne peut être cédé sans l’accord de la Société WIZZME .
                        </p>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">Article 3 Tarifs</h3>
                        <p className="text-gray-500">
                            <span className="font-medium text-gray-900">3-1</span> - Les Prestations de services sont fournies aux tarifs en vigueur au jour de la passation de la commande, selon le tarif affiché sur le
                            site internet planileo.fr - Une facture est établie par la Société WIZZME et remise au Client lors de chaque fourniture de services.
                        </p>
                        <p className="text-gray-500">
                        <span className="font-medium text-gray-900">3-2</span> - Le Client pourra bénéficier de réductions de prix, rabais, remises et ristournes, en fonction des quantités de prestations de
                            services commandées, en une seule fois et un seul lieu, ou de la régularité de ses commandes de prestations de services, dans les
                            conditions et selon les modalités décrites aux tarifs de la Société WIZZME .
                        </p>

                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">Article 4 Conditions de règlement</h3>
                        <p className="font-medium">4.1 Délais de règlement</p>
                        <p className="text-gray-500">
                            Les prestations sont payable par carte bancaire mensuellement, sur abonnement.
                            La Société WIZZME ne sera pas tenue de procéder à la fourniture des prestations de services commandées par le Client si celui-ci ne
                            lui en paye pas le prix dans les conditions et selon les modalités indiquées aux présentes Conditions Générales de Vente. 
                        </p>
                        <p className="text-gray-500">
                            <span className="font-medium text-gray-900">4-2</span> - En cas de non-respect des conditions de paiement figurant ci-dessus, la Société WIZZME se réserve en outre le droit de suspendre
                            ou d’annuler la fourniture des prestations de services commandées par le Client, de suspendre l’exécution de ses obligations et de
                            diminuer ou d’annuler les éventuelles remises accordées à ce dernier.
                        </p>
                        <p className="font-medium">4.3 Absence de compensation</p>
                        <p className="text-gray-500">
                            Sauf accord exprès, préalable et écrit de la Société WIZZME , et à conditions que les créances et dettes réciproques soient certaines,
                            liquides et exigibles, aucune compensation ne pourra être valablement effectuée par le Client entre d’éventuelles pénalités pour
                            retard dans la fourniture des prestations de services commandées ou non-conformité des prestations à la commande, d’une part, et
                            les sommes dues par le Client à la Société WIZZME au titre de l’achat desdites prestations, d’autre part
                        </p>
                        <h3 className="mt-6 mb-2 text-xl font-bold dark:text-white">Article 5 Modalités de fourniture des prestations</h3>
                        <p className="text-gray-500">
                            Les prestations de services demandées par le Client seront fournies dès validation du paiement mensuel.
                            La responsabilité de la Société WIZZME ne pourra en aucun cas être engagée en cas de retard ou de suspension de la fourniture de la
                            prestation imputable au Client, ou en cas de force majeure.
                        </p>

                        <p className="mt-6 mb-6 text-gray-500">Vourey le 24 janvier 2025</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default ConditionsVente;