import React, { useState, useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { AdminAuth, FormateurAuth, OrganismeAuth } from "./auth/ClientAuth"
import {Helmet} from "react-helmet"
import Home from "./scenes/homepage/Home"
import ConditionsVente from "./scenes/conditionsVente/ConditionsVente"
import PolitiqueConfidentialite from "./scenes/politiqueConfidentialite/PolitiqueConfidentialite"
import Login from "./scenes/login/Login"
import ForgotPassword from "./scenes/forgotPassword/ForgotPassword"
import ResetPassword from "./scenes/resetPassword/ResetPassword"
import Register from "./scenes/register/Register"
import NoPage from "./scenes/NoPage"
import AdminDashboard from "./scenes/admin/Dashboard"
import AdminOrganismes from "./scenes/admin/Organismes"
import AdminFormateurs from "./scenes/admin/Formateurs"
import OrgDashboard from "./scenes/organismes/Dashboard"
import OrgHelp from "./scenes/organismes/Help"
import OrgMyAccount from "./scenes/organismes/MyAccount"
import OrgMonPlanning from "./scenes/organismes/OrgMonPlanning"
import OrgMesFormateurs from "./scenes/organismes/MesFormateurs"
import OrgFormPlanning from "./scenes/organismes/FormPlanning"

import FormDashboard from "./scenes/formateurs/Dashboard";
import FormMyAccount from "./scenes/formateurs/MyAccount";
import FormOrganismes from "./scenes/formateurs/FormOrganismes";
import FormPlanning from "./scenes/formateurs/MonPlanning"
import FormGuide from "./scenes/formateurs/Guide"
import FormHelp from "./scenes/formateurs/Help"

import "./App.css";




function App() {
  document.documentElement.lang = 'fr'

  return (
    <div className="App notranslate" translate="no">
      <Helmet>
        <meta name="google" content="notranslate" />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/conditions-generales-de-vente" element={<ConditionsVente />} />
          <Route path="/politique-de-confidentialite" element={<PolitiqueConfidentialite />} />
          <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
          <Route path="/nouveau-mot-de-passe" element={<ResetPassword />} />
          <Route path="/inscription" element={<Register />} />
          <Route path="/admin/dashboard" element={<AdminAuth> <AdminDashboard /> </AdminAuth>} />
          <Route path="/admin/organismes" element={<AdminAuth><AdminOrganismes /></AdminAuth>} />
          <Route path="/admin/formateurs" element={<AdminAuth><AdminFormateurs /></AdminAuth>} />

          <Route path="/org/tableau-de-bord" element={<OrganismeAuth><OrgDashboard /></OrganismeAuth>} />
          <Route path="/org/aide" element={<OrganismeAuth><OrgHelp /></OrganismeAuth>} />
          <Route path="/org/mon-planning" element={<OrganismeAuth><OrgMonPlanning /></OrganismeAuth>} />
          <Route path="/org/mon-compte" element={<OrganismeAuth><OrgMyAccount /></OrganismeAuth>} />
          <Route path="/org/mes-formateurs" element={<OrganismeAuth><OrgMesFormateurs /></OrganismeAuth>} />
          <Route path="/org/planning-formateur/:id" element={<OrganismeAuth><OrgFormPlanning /></OrganismeAuth>} />

          <Route path="/form/tableau-de-bord" element={<FormateurAuth><FormDashboard /></FormateurAuth>} />
          <Route path="/form/mon-compte" element={<FormateurAuth><FormMyAccount /></FormateurAuth>} />
          <Route path="/form/mes-organismes" element={<FormateurAuth><FormOrganismes /></FormateurAuth>} />
          <Route path="/form/mon-planning" element={<FormateurAuth><FormPlanning /></FormateurAuth>} />
          <Route path="/form/guide" element={<FormateurAuth><FormGuide /></FormateurAuth>} />
          <Route path="/form/aide" element={<FormateurAuth><FormHelp /></FormateurAuth>} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;