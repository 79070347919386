import LoginForm from "./LoginForm";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { Alert } from "flowbite-react";
import Menu from "../../components/Menu";
import { HiInformationCircle } from "react-icons/hi";
import { useCookies } from 'react-cookie'
import { useNavigate, useSearchParams } from 'react-router-dom'


function Login() {
    const [Registered, setRegistered] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [cookies] = useCookies(['userInfo']);
    const [searchParams, setSearchParams] = useSearchParams();
    const justRegistered = searchParams.get("justRegistered")

    useEffect(() => {
        if (cookies.userInfo) {
            navigate('/', { replace: true })
        }
        if (state && state.justRegistered === true || justRegistered === "true" ) {
            setRegistered(true)
        }
    }, []);

    return (
        <>
            <Menu displayButtons={false} />
            <div className="flex flex-col items-center justify-center w-screen h-screen -mt-12">
                {Registered &&
                    <Alert className="w-full max-w-lg mb-4" color="success" icon={HiInformationCircle}>
                        <span className="font-medium">Votre compte a été créé ! Vous pouvez vous connecter ci-dessous</span>
                    </Alert>
                }
                <LoginForm />
            </div>
        </>
    );
}
export default Login;