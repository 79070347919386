import React, { useEffect, useState } from 'react';
import Layout from "./global/Layout";
import { useNavigate } from 'react-router-dom'
import { Table, Button, Badge, Modal } from "flowbite-react";
import { parseSqlDateRange } from '../../utils/utils';
import { useCookies } from 'react-cookie'

function OrgDashboard() {
    let [awaitingOptions, setAwaitingOptions] = useState(false);
    let [nextOptions, setNextOptions] = useState(false);
    let [user, setUser] = useState([]);
    const [cookies] = useCookies(['userInfo'])
    const navigate = useNavigate();

    useEffect(() => {
        getAwaitingOptions()
        getUserInfo()
        getNextOptions()
    }, []);

    const getUserInfo = async () => {
        const response = await fetch('https://api.planileo.fr/users/getUserInfo/' + cookies.userInfo.id, {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const res = await response.json()
            setUser(res)
        }
    };
    const getAwaitingOptions = async () => {
        const response = await fetch('https://api.planileo.fr/orgs/getAwaitingOptions', {
            method: 'GET',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue. Veuillez réessayer plus tard.')
        } else {
            const result = await response.json();
            setAwaitingOptions(result.data);
        }
    };
    const acceptOption = async (id) => {
        const response = await fetch('https://api.planileo.fr/planning/acceptOption/' + id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                window.location.reload()
            } else {
                alert(result.message)
            }
        }
    }
    const getNextOptions = async () => {
        const response = await fetch('https://api.planileo.fr/orgs/getNextOptions', {
            method: 'GET',
            credentials: 'include'
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue. Veuillez réessayer plus tard.')
        } else {
            const result = await response.json();
            setNextOptions(result.data);
        }
    };


    return (
        <Layout>
            <div className="pt-6">
                <div className="flex items-center">
                    <h3 className="text-xl font-semibold mr-3">Tableau de bord</h3>
                    {user.plan === 0 ? (
                        <Badge color="indigo">Abonnement gratuit - 5 formateurs</Badge>
                    ) : user.plan === 99 ? (
                        <Badge color="indigo">Abonnement spécial - formateurs illimités</Badge>
                    ) : user.plan === 1 ? (
                        <Badge color="indigo">Abonnement bronze - 20 formateurs</Badge>
                    ): user.plan === 2 ? (
                        <Badge color="indigo">Abonnement agent - 50 formateurs</Badge>
                    ): (
                        <Badge color="indigo">Abonnement or - formateurs illimités</Badge>
                    )}
                </div>
                <p>Bienvenue dans votre espace organisme de formation</p>
                    <div className="mt-6 pb-8">
                        <h3 className="text-l font-semibold">Options à valider</h3>
                        <p className="text-gray-500 text-sm">Vous pouvez voir toutes vos options dans <a className="underline" href="/org/mon-planning">votre planning</a>.</p>
                        {awaitingOptions && awaitingOptions.length === 0 ? (
                            <p className="font-small text-gray-600 mt-2">Pas d'options à valider pour l'instant</p>
                        ) : (
                            <Table className="mt-2">
                                <Table.Head className="sm:contents hidden">
                                    <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Formateur</Table.HeadCell>
                                    <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Titre</Table.HeadCell>
                                    <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Date de la formation</Table.HeadCell>
                                    <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">État</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {awaitingOptions && awaitingOptions.length > 0 && awaitingOptions.map((option, index) => (
                                        <Table.Row key={index} className="flex flex-col sm:table-row bg-white min-w-[320] dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                                {option.prenom} {option.nom}
                                            </Table.Cell>
                                            <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                                {option.title}
                                            </Table.Cell>
                                            <Table.Cell className="px-2 py-2 text-small md:font-medium md:px-6 md:py-4">{parseSqlDateRange(option.startdate, option.enddate)}</Table.Cell>
                                            <Table.Cell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">
                                                {option.etat === 1 ?(
                                                    <div className="flex items-center">
                                                    <Badge color="warning" className="mr-2">Validation finale</Badge>
                                                    <Button size="sm" color="blue" onClick={() => {acceptOption(option.id)} }>Confirmer l'option</Button>
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center"><Badge color="info">En attente du formateur</Badge></div>
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </div>
                    <div className="mt-6 pb-8">
                        <h3 className="text-l font-semibold">Prochaines formations</h3>
                        <p className="text-gray-500 text-sm">Vous pouvez voir toutes les formations dans <a className="underline" href="/org/mon-planning">votre planning</a>.</p>
                        {nextOptions && nextOptions.length === 0 ? (
                            <p className="font-small text-gray-600 mt-2">Pas de formations à venir pour l'instant</p>
                        ) : (
                            <Table className="mt-2">
                                <Table.Head className="sm:contents hidden">
                                    <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Formateur</Table.HeadCell>
                                    <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Titre</Table.HeadCell>
                                    <Table.HeadCell className="px-2 py-2 font-small md:font-medium md:px-6 md:py-4">Date de la formation</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {nextOptions && nextOptions.length > 0 && nextOptions.map((option, index) => (
                                        <Table.Row key={index} className="flex flex-col sm:table-row bg-white min-w-[320] dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                                {option.prenom} {option.nom}
                                            </Table.Cell>
                                            <Table.Cell className="px-2 py-2 font-small border-t-2 border-gray-300 sm:border-t-0 text-gray-900 md:font-medium">
                                                {option.title}
                                            </Table.Cell>
                                            <Table.Cell className="px-2 py-2 text-small md:font-medium md:px-6 md:py-4">{parseSqlDateRange(option.startdate, option.enddate)}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </div>
            </div>
        </Layout>
    );
}
export default OrgDashboard;